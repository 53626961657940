import React from 'react'
import withStyles from '@material-ui/styles/withStyles'

import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  paddedText: {
    position: 'relative',
    lineHeight: 1.4,
    color: theme.palette.headerContainer.contrastText,
    display: 'inline',
    whiteSpace: 'pre-wrap',
    // borderStyle: 'solid',
    // borderColor: props =>
    // props.colorBg ? props.colorBg : theme.palette.primary.main,
    // borderWidth: '0.25em 0',
    background: props =>
      props.colorBg ? props.colorBg : theme.palette.primary.main,
    padding: 0,
    boxDecorationBreak: 'clone',
    boxShadow: props => {
      const bg = props.colorBg ? props.colorBg : theme.palette.primary.main
      return '.25em 0 0 ' + bg + ', -.25em 0 0 ' + bg
    },
    fontSize: theme.typography.pxToRem(24),

    fontWeight: 600,
    '&:before': {
      content: '""',
      marginBottom: 15,
    },
    '&:after': {
      content: '""',
      display: 'block',
      marginBottom: '.25em',
      height: 0,
    },
    '&> span': {
      position: 'relative',
      zIndex: 1,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(60),
    },
  },
})

const ATextPadded = ({ classes, children, color, variant }) => (
  <Typography variant={variant} color={color} className={classes.paddedText}>
    <span>{children}</span>
  </Typography>
)

ATextPadded.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  colorBg: PropTypes.string,
  variant: PropTypes.string,
}

ATextPadded.defaultProps = {
  color: 'secondary',
  variant: 'h2',
}

// not using withStyles to be able to use props in styles
// see @url https://github.com/mui-org/material-ui/issues/8726#issuecomment-408274180
export default withStyles(styles)(ATextPadded)
