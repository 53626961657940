import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  paperContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
})

/** APaper */
class APaper extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children, classes, ...rest } = this.props

    return (
      <Paper className={classes.paperContainer} elevation={0} {...rest}>
        {children}
      </Paper>
    )
  }
}

APaper.propTypes = {
  /** The paper children nodes */
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(APaper)
