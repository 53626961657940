import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ABackgroundImg extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { image, children, ...rest } = this.props
    return (
      <div
        {...rest}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
        }}
      >
        {children}
      </div>
    )
  }
}

ABackgroundImg.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ABackgroundImg
