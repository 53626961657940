import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Grid from '@material-ui/core/Grid'

import classNames from 'classnames'

import ABackgroundImg from '../01-atoms/a-background-img'
import ATextPadded from '../01-atoms/a-text-padded'

import headerImg from './../../assets/images/img-subheader.png'

const styles = theme => ({
  divider: {
    marginBottom: theme.spacing(3),
  },
  headerContainer: {
    color: theme.palette.headerContainer.contrastText,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      height: 260,
    },
    height: 90,
  },
  headerContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(11),
      paddingRight: theme.spacing(11),
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignSelf: 'center',
    ...theme.customStyles.mainContent,
  },
  contentWidth: {
    ...theme.customStyles.mainContent,
  },

  paperContainerHeadline: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-10),
    },
  },
  backgroundImage: {
    [theme.breakpoints.up('sm')]: {
      height: 340,
    },
    height: 90,
  },
})

class SimplePaperTemplateWrapper extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children, classes, headline, showHeadlineBar } = this.props
    const paperContainer = classNames(
      showHeadlineBar ? classes.paperContainerHeadline : ''
    )
    return (
      <Fragment>
        <ABackgroundImg className={classes.backgroundImage} image={headerImg}>
          <Grid className={classes.headerContainer} container>
            {showHeadlineBar ? (
              <Grid item xs={12} className={classes.headerContent}>
                {headline ? (
                  <ATextPadded colorBg="#000">{headline}</ATextPadded>
                ) : (
                  false
                )}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </ABackgroundImg>

        <Grid container justify="center">
          <Grid item xs={12} className={classes.contentWidth}>
            <div className={paperContainer}>{children}</div>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

SimplePaperTemplateWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  showHeadlineBar: PropTypes.bool,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
}
export default withStyles(styles)(SimplePaperTemplateWrapper)
